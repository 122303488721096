exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-software-provider-selector-software-provider-selector-styles__title {\n  text-align: center;\n  margin-bottom: 1.6rem;\n  font-size: 1.8rem;\n  font-weight: 800;\n}\n\n.src-components-software-provider-selector-software-provider-selector-styles__container {\n  padding: 2.4rem;\n}\n\n.src-components-software-provider-selector-software-provider-selector-styles__exportTips {\n  animation-name: src-components-software-provider-selector-software-provider-selector-styles__grow-open;\n  animation-duration: 1s;\n  animation-timing-function: linear;\n}\n\n\n.src-components-software-provider-selector-software-provider-selector-styles__exportTips li {\n  padding: 0.4rem 0;\n}\n\n.src-components-software-provider-selector-software-provider-selector-styles__exportTipsTitle {\n  font-weight: 600;\n  padding: 0.8rem 0 0 0;\n}\n\n@keyframes src-components-software-provider-selector-software-provider-selector-styles__grow-open {\n  0% {\n    max-height: 0;\n    overflow: hidden;\n  }\n  99.99% {\n    overflow: hidden;\n  }\n  100% {\n    max-height: 300px;\n  }\n}", ""]);

// Exports
exports.locals = {
	"title": "src-components-software-provider-selector-software-provider-selector-styles__title",
	"container": "src-components-software-provider-selector-software-provider-selector-styles__container",
	"exportTips": "src-components-software-provider-selector-software-provider-selector-styles__exportTips",
	"grow-open": "src-components-software-provider-selector-software-provider-selector-styles__grow-open",
	"exportTipsTitle": "src-components-software-provider-selector-software-provider-selector-styles__exportTipsTitle"
};