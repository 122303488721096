import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './csv-uploader.styles.css';
import {postCsvFile} from '../../mapping-resource';
import {asyncStacktrace, catchSyncStacktrace} from 'auto-trace';
import {ImporterDispatch} from 'src/importer.context';
import {uploadStates} from './csv-uploader.helper';
import CsvSelector from './csv-selector/csv-selector.component';
import UploadingCsv from './uploading-csv/uploading-csv.component';
import UploadedCsv from './uploaded-csv/uploaded-csv.component';
import UploadCsvError from './upload-csv-error/upload-csv-error.component';

CsvUploader.propTypes = {
  csvFile: PropTypes.object,
  uploadState: PropTypes.string,
}

export default function CsvUploader(props) {
  const dispatch = useContext(ImporterDispatch);
  useCsvUploader();

  return renderComponent();

  function renderComponent() {
    if (!props.csvFile) {
      // no .csv file has been selected
      return (
        <CsvSelector />
      )
    } else {
      // .csv file has been selected
      if (props.uploadState === uploadStates.UPLOADING) {
        return (
          <UploadingCsv
            csvFile={props.csvFile}
          />
        )
      } else if (props.uploadState === uploadStates.UPLOADED) {
        return (
          <UploadedCsv
            csvFile={props.csvFile}
          />
        )
      } else {
        return (
          <UploadCsvError
            csvFile={props.csvFile}
          />
        )
      }
    }
  }

  function useCsvUploader() {
    useEffect(() => {
      if (props.csvFile && (!props.uploadState || props.uploadState === uploadStates.UPLOADING)) {
        dispatch({type: 'set_upload_state', payload: uploadStates.UPLOADING})
    
        const subscription = postCsvFile(props.csvFile).subscribe(
          response => {
            const dataColumns = response.sources.source_columns.map((header, index) => {
              return {
                header,
                field: null,
                data: response.sources.source_data[index]
              }
            })
    
            dispatch({type: 'complete_csv_upload', payload: {
              uploadState: uploadStates.UPLOADED,
              dataColumns,
              totalRowCount: response.sources.total_rows,
              mappingTemplates: response.sources.mapping_templates,
              sourceId: response.sources.id
            }})
          },
          asyncStacktrace(err => {
            dispatch({type: 'set_upload_state', payload: uploadStates.ERROR})
            catchSyncStacktrace(err)
          })
        )

        return () => subscription.unsubscribe();
      }
    }, [props.csvFile, props.uploadState])
  }
}