exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-contact-type-selector-contact-type-selector-styles__title {\n  text-align: center;\n  font-size: 1.8rem;\n  font-weight: 800;\n}\n\n.src-components-contact-type-selector-contact-type-selector-styles__subtitle {\n  text-align: center;\n  margin-bottom: 1.6rem;\n}\n\n.src-components-contact-type-selector-contact-type-selector-styles__container {\n  margin: 0 88px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.src-components-contact-type-selector-contact-type-selector-styles__button, .src-components-contact-type-selector-contact-type-selector-styles__button:hover, .src-components-contact-type-selector-contact-type-selector-styles__button:focus {\n  border: 0.1rem solid var(--cps-color-silver);\n  border-radius: 0.5rem;\n  width: 188px;\n  height: 5.6rem;\n  outline: none;\n}\n\n.src-components-contact-type-selector-contact-type-selector-styles__buttonFlex {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  padding: 0 2.4rem;\n}\n\n.src-components-contact-type-selector-contact-type-selector-styles__buttonText {\n  margin: 0 1.2rem;\n}\n\n.src-components-contact-type-selector-contact-type-selector-styles__selectedButton, .src-components-contact-type-selector-contact-type-selector-styles__selectedButton:hover, .src-components-contact-type-selector-contact-type-selector-styles__selectedButton:focus {\n  border: 2px solid var(--cps-color-primary);\n  background-color: transparent;\n}", ""]);

// Exports
exports.locals = {
	"title": "src-components-contact-type-selector-contact-type-selector-styles__title",
	"subtitle": "src-components-contact-type-selector-contact-type-selector-styles__subtitle",
	"container": "src-components-contact-type-selector-contact-type-selector-styles__container",
	"button": "src-components-contact-type-selector-contact-type-selector-styles__button",
	"buttonFlex": "src-components-contact-type-selector-contact-type-selector-styles__buttonFlex",
	"buttonText": "src-components-contact-type-selector-contact-type-selector-styles__buttonText",
	"selectedButton": "src-components-contact-type-selector-contact-type-selector-styles__selectedButton"
};