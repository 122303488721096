import React, {useEffect, useRef} from "react";
import PropTypes from 'prop-types';
import styles from './attributes-search.styles.css';
import {CprIcon} from 'canopy-styleguide!sofe';
import {a} from 'kremling';

AttributesSearch.propTypes = {
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  optionFocusIndex: PropTypes.number.isRequired,
  setOptionFocusIndex: PropTypes.func.isRequired,
}

export default function AttributesSearch(props) {
  const searchInputRef = useRef(null);

  useSearchInputFocus();

  return (
    <div className={styles.searchContainer}>
      <CprIcon
        name="misc-magnifying-glass"
        size={24}
      />
      <input
        ref={searchInputRef}
        type="text"
        className={a(`cps-form-control`, styles.searchInput).m(styles.italics, !props.searchValue)}
        placeholder="Search"
        value={props.searchValue}
        onChange={handleSearchInputChange}
        onKeyDown={handleSearchInputKeyDown}
      />
    </div>
  )

  function useSearchInputFocus() {
    useEffect(() => {
      if (searchInputRef && searchInputRef.current) {
        if (props.optionFocusIndex === -1) {
          searchInputRef.current.focus();
        }
      }
    }, [props.optionFocusIndex])
  }

  function handleSearchInputChange(e) {
    props.setOptionFocusIndex(-1);
    props.setSearchValue(e.target.value);
  }

  function handleSearchInputKeyDown(e) {
    switch (e.key) {
      case 'Tab':
        props.setOptionFocusIndex(0);
      break;
    }
  }
}