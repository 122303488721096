import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './finalize-import-modal.styles.css';
import {CprButton} from 'canopy-styleguide!sofe';
import {postMappingTemplate} from '../../mapping-resource.js';
import {catchAsyncStacktrace} from 'auto-trace';
import {finalize} from 'rxjs/operators';
import {mapDataColumnsToFieldMappingsApi} from '../../import-contacts-modal.helper';
import useFocusTrap from 'src/hooks/use-focus-trap.hook';

FinalizeImportModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  dataColumns: PropTypes.array.isRequired,
}

export default function FinalizeImportModal(props) {
  const modalRef = useRef(null);
  const [isSaveChecked, setIsSaveChecked] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  useFocusTrap(modalRef);
  useSave();

  return (
    <div
      ref={modalRef}
      className="cps-modal"
    >
      <div className="cps-modal__screen" />
      <div className="cps-modal__dialog cps-card__height-3">
        <div className="cps-card__header cps-subheader">
          Validating and finalizing import
        </div>
        <div className="cps-card__body">
          <p className="cps-body cps-wt-semibold">We'll email you.</p>
          <p>
            We're checking for any additional errors and finalizing your import, but this might take a minute.  We'll email you once the import is complete, along with any errors we detected.
          </p>
          <div>
            <p className="cps-body cps-wt-semibold">
              Save this mapping as a template?
            </p>
            <p>
              To save you time on your next import, would you like to save this mapping as a template?
            </p>
            <div className="cps-well cps-padding-12">
              <label className="cps-checkbox">
                <input
                  type="checkbox"
                  checked={isSaveChecked}
                  onChange={e => setIsSaveChecked(e.target.checked)}
                  data-testid="save-template-checkbox"
                  data-checked={isSaveChecked}
                />
                <span>Save this mapping as an import template</span>
              </label>
              {isSaveChecked && (
                <div className={styles.templateNameContainer}>
                  <hr />
                  <div className={styles.templateNameInput}>
                    <input
                      type="text"
                      required="required"
                      maxLength={255}
                      className="cps-form-control cps-margin-top-16 cps-margin-bottom-8"
                      placeholder="Name of template"
                      value={templateName}
                      onChange={e => setTemplateName(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="cps-modal__dialog__actions">
          <CprButton
            actionType="primary"
            disabled={isSaving || isError(isSaveChecked, templateName)}
            onClick={() => isSaveChecked ? setIsSaving(true) : props.closeModal()}
          >
            {isSaveChecked ? 'Save and exit' : 'Return to contacts'}
          </CprButton>
        </div>
      </div>
    </div>
  )

  function useSave() {
    useEffect(() => {
      if (isSaving) {
        const subscription = postMappingTemplate(templateName, mapDataColumnsToFieldMappingsApi(props.dataColumns)).pipe(
          finalize(() => {
            setIsSaving(false);
          })
        )
        .subscribe(
          () => props.closeModal(),
          catchAsyncStacktrace()
        )

        return () => subscription.unsubscribe();
      }
    }, [isSaving])
  }
}

function isError(isSaveChecked, templateName) {
  let error = null;

  if (isSaveChecked) {
    if (!templateName.length) {
      error = "Template name cannot be blank";
    }
  }
 
  return error;
}