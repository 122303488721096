import React, {useState, useEffect, useRef} from "react"
import PropTypes from 'prop-types';
import styles from './canopy-attributes-dropdown.styles.css';
import CanopyAttributesButton from './canopy-attributes-button/canopy-attributes-button.component';
import AttributesPage from './attributes-page/attributes-page.component';
import CustomFieldPage from './custom-field-page/custom-field-page.component';

CanopyAttributesDropdown.propTypes = {
  canopyFields: PropTypes.array.isRequired,
  customFields: PropTypes.array.isRequired,
  column: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
}

export default function CanopyAttributesDropdown(props) {
  const dropdownRef = useRef(null);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [createEditNewCustomFieldProps, setCreateEditNewCustomFieldProps] = useState(null);

  useCloseDocumentClickListener();

  return (
    <div
      ref={dropdownRef}
      className={styles.dropdown}
    >
      <CanopyAttributesButton
        column={props.column}
        index={props.index}
        dropdownIsOpen={dropdownIsOpen}
        setDropdownIsOpen={setDropdownIsOpen}
      />
      {dropdownIsOpen && (
        <div className={styles.dropdownContainer}>
          {createEditNewCustomFieldProps ? (
            <CustomFieldPage
              name={createEditNewCustomFieldProps.name}
              type={createEditNewCustomFieldProps.type}
              canopyFields={props.canopyFields}
              customFields={props.customFields}
              column={props.column}
              setDropdownIsOpen={setDropdownIsOpen}
              setCreateEditNewCustomFieldProps={setCreateEditNewCustomFieldProps}
            />
          ) : (
            <AttributesPage
              canopyFields={props.canopyFields}
              customFields={props.customFields}
              column={props.column}
              setDropdownIsOpen={setDropdownIsOpen}
              setCreateEditNewCustomFieldProps={setCreateEditNewCustomFieldProps}
            />
          )}
        </div>
      )}
    </div>
  )

  function useCloseDocumentClickListener() {
    useEffect(() => {
      if (dropdownIsOpen) {
        document.addEventListener('click', attemptClose);
      }

      return () => document.removeEventListener('click', attemptClose);

      function attemptClose(e) {
        if (dropdownRef && dropdownRef.current) {
          if (e.keepDropdownOpen || dropdownRef === e.target || dropdownRef.current.contains(e.target)) {
            return;
          }

          setCreateEditNewCustomFieldProps(null);
          setDropdownIsOpen(false);
        }
      }
    }, [dropdownIsOpen])
  }
}