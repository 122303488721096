import React from "react"
import PropTypes from 'prop-types';
import CanopyAttributesDropdown from './canopy-attributes-dropdown/canopy-attributes-dropdown.component';
import styles from './csv-data-column.styles.css';
import {a} from 'kremling';
import {doNotImportOption} from 'src/import-contacts-modal.helper';

CsvDataColumn.propTypes = {
  column: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  canopyFields: PropTypes.array.isRequired,
  customFields: PropTypes.array.isRequired,
  totalRowCount: PropTypes.number.isRequired,
}

const sampleRowCount = 14;

export default function CsvDataColumn(props) {
  return (
    <div className={styles.column}>
      <input
        className={a('cps-form-control', styles.header)}
        disabled={true}
        placeholder={props.column.header}
      />
      <div className={styles.canopyAttributeDropdown}>
        <CanopyAttributesDropdown
          canopyFields={props.canopyFields}
          customFields={props.customFields}
          column={props.column}
          index={props.index}
        />
      </div>
      <div className={styles.dataContainer}>
        <div
          className={
            a('cps-wt-semibold', styles.dataHeader)
            .m(styles.isMappedHeader, hasField())
            .m(styles.doNotImportHeader, hasDoNotImportOption())
          }
        >
          <div className={styles.textOverflowEllipsis}>
            {renderMappedToText()}
          </div>
        </div>
        <div
          className={
            a(styles.rowsContainer)
            .m(styles.isMappedContainer, hasField())
            .m(styles.doNotImportContainer, hasDoNotImportOption())
          }
        >
          {props.column.data.slice(0, sampleRowCount).map((row, index) =>
            <div
              key={index}
              className={a(styles.row)}>
              <div className={styles.textOverflowEllipsis}>
                {row}
              </div>
            </div>
          )}
          {props.totalRowCount > sampleRowCount && (
            <div className={a(styles.row, styles.moreRow)}>
              {`${props.totalRowCount - sampleRowCount} more rows...`}
            </div>
          )}
        </div>
      </div>
    </div>
  )

  function renderMappedToText() {
    if (hasField()) {
      return `Mapped to ${props.column.field.displayLabel}`;
    } else if (hasDoNotImportOption()) {
      return `Do not import`;
    } else {
      return `Not mapped`;
    }
  }

  function hasField() {
    return props.column.field && props.column.field.displayLabel !== doNotImportOption.displayLabel;
  }

  function hasDoNotImportOption() {
    return props.column.field && props.column.field.displayLabel === doNotImportOption.displayLabel;
  }
}