import React from "react";
import PropTypes from 'prop-types';
import styles from './canopy-attributes-button.styles.css';
import {CprButton, CprIcon} from 'canopy-styleguide!sofe';
import {m} from 'kremling';

CanopyAttributesButton.propTypes = {
  column: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  dropdownIsOpen: PropTypes.bool.isRequired,
  setDropdownIsOpen: PropTypes.func.isRequired,
}

export default function CanopyAttributesButton(props) {
  return (
    <div className={styles.button}>
      {/* -- for screen reader -- */}
      <span
        id={`import-contacts_canopy-attributes-button_${props.index}`}
        style={{display: 'none'}}
      >
        {`${props.column.header} CSV Header ${props.column.field ? props.column.field.displayLabel : 'Select an attribute...'}`}
      </span>
      {/* ----------------------- */}
      <CprButton
        className={m(styles.active, props.dropdownIsOpen)}
        actionType="unstyled"
        type="button"
        onClick={() => props.setDropdownIsOpen(true)}
        aria-labelledby={`import-contacts_canopy-attributes-button_${props.index}`}
        aria-haspopup="listbox"
        aria-expanded={props.dropdownIsOpen ? 'true' : 'false'}
      >
        <div className={styles.left}>
          {props.column.field && (props.column.field.isCustomField || props.column.field.isNewCustomField) && (
            <div className={styles.customFieldIcon}>
              <CprIcon
                name="misc-text-input"
              />
            </div>
          )}
          <div className={styles.label}>
            {props.column.field ? props.column.field.displayLabel : 'Select an attribute...'}
          </div>
        </div>
        <div className={styles.right}>
          <CprIcon
            name="sm-caret-down"
            size={24}
          />
        </div>
      </CprButton>
    </div>
  )
}