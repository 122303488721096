exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-csv-uploader-csv-selector-csv-selector-styles__dropZone {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border: 2px dashed var(--cps-color-silver);\n  padding: 2.4rem;\n  background-color: var(--cps-color-blue-smoke);\n}\n\n.src-components-csv-uploader-csv-selector-csv-selector-styles__overDropZone {\n  border: 2px dashed var(--cps-color-green);\n  background-color: #F0FBF5;\n}\n\n.src-components-csv-uploader-csv-selector-csv-selector-styles__csvImage {\n  margin: 1.2rem;\n}\n\n.src-components-csv-uploader-csv-selector-csv-selector-styles__title {\n  font-size: 2.4rem;\n  font-weight: 800;\n}\n\n.src-components-csv-uploader-csv-selector-csv-selector-styles__subtitle {\n  font-size: 1.6rem;\n  margin-bottom: 1.6rem;\n}", ""]);

// Exports
exports.locals = {
	"dropZone": "src-components-csv-uploader-csv-selector-csv-selector-styles__dropZone",
	"overDropZone": "src-components-csv-uploader-csv-selector-csv-selector-styles__overDropZone",
	"csvImage": "src-components-csv-uploader-csv-selector-csv-selector-styles__csvImage",
	"title": "src-components-csv-uploader-csv-selector-csv-selector-styles__title",
	"subtitle": "src-components-csv-uploader-csv-selector-csv-selector-styles__subtitle"
};