import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { ErrorBoundary } from "error-logging!sofe";
import { ImportContactsModal } from "./import-contacts-modal.component";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: ErrorBoundary({ featureName: "data-mapping-import-contacts" })(
    ImportContactsModal
  ),
});

const featureToggles = [];

const bootstrap = [
  () => {
    if (!!featureToggles.length) {
      return SystemJS.import("feature-toggles!sofe").then((ft) =>
        ft.fetchFeatureToggles(...featureToggles)
      );
    } else {
      return Promise.resolve();
    }
  },
  reactLifecycles.bootstrap,
];

const ImportContactsParcel = {
  ...reactLifecycles,
  bootstrap,
};

export default ImportContactsParcel;
