import React, {useRef, useState, useEffect} from 'react';
import createFocusTrap from 'focus-trap';

const ACTIVATION_TRY_COUNT_LIMIT = 500;

const DEFAULT_OPTIONS = {
  escapeDeactivates: false,
}

export default function useFocusTrap(modalRef, createOptions = {}) {
  const options = {...DEFAULT_OPTIONS, ...createOptions}

  const [focusTrap, setFocusTrap] = useState(null);
  const [activationTryCount, setActivationTryCount] = useState(0);
  const [isActivated, setIsActivated] = useState(false);
  const tryAgainTimeout = useRef(null);

  useTrap();

  return isActivated;

  function useTrap() {
    useEffect(() => {
      if (modalRef.current) {
        setFocusTrap(createFocusTrap(modalRef.current, options));
      }
    }, [modalRef.current])
    
    useEffect(() => {
      let tryAgain = false;
      if (focusTrap) {
        try {
          focusTrap.activate();
          setIsActivated(true);
        } catch(err) {
          if (activationTryCount < ACTIVATION_TRY_COUNT_LIMIT) {
            tryAgain = true;
          } else {
            throw err;
          }
        }
      }

      if (tryAgain) {
        clearTryAgainTimeout();
        tryAgainTimeout.current = setTimeout(() => {
          setActivationTryCount(prevCount => prevCount + 1);
        }, 250)
      }
    
      return () => {
        clearTryAgainTimeout();

        if (focusTrap) {
          focusTrap.deactivate();
          setIsActivated(false);
        }
      }
    }, [focusTrap, activationTryCount])
  }

  function clearTryAgainTimeout() {
    if (tryAgainTimeout.current || tryAgainTimeout.current === 0) {
      clearTimeout(tryAgainTimeout.current);
      tryAgainTimeout.current = null;
    }
  }
}