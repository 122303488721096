import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CprShortSingleSelect } from 'canopy-styleguide!sofe';

import styles from './software-provider-selector.styles.css';
import { softwareProviderOptions } from '../../import-contacts-modal.helper';
import IntuitProSeriesTips from './intuit/intuit-pro-series-tips.component';
import LacerteTips from './lacerte/lacerte-tips.component';
import UltraTaxTips from './ultra-tax/ultra-tax-tips.component';
import AtxTips from './atx/atx-tips.component';
import TaxWiseTips from './tax-wise/tax-wise-tips.component';
import OtherTips from './other/other-tips.component';
import { templateTypes } from '../../common/common.helper';
import { ImporterDispatch } from 'src/importer.context';

SoftwareProviderSelector.propTypes = {
  softwareProvider: PropTypes.object,
};

const softwareProviderTips = {
  'Intuit ProSeries': <IntuitProSeriesTips />,
  Lacerte: <LacerteTips />,
  UltraTax: <UltraTaxTips />,
  ATX: <AtxTips />,
  TaxWise: <TaxWiseTips />,
  Other: <OtherTips />,
};

export default function SoftwareProviderSelector(props) {
  const dispatch = useContext(ImporterDispatch);

  return (
    <>
      <div className={styles.title}>Select a software provider.</div>
      <div className={styles.container}>
        <CprShortSingleSelect
          labelText="Where are you importing contacts from?"
          placeholderText="Select previous software"
          options={softwareProviderOptions}
          optionSelected={handleOptionSelected}
          initialSelection={
            props.softwareProvider && props.softwareProvider.key
          }
          width="316px"
          listWidth="316px"
        />
        {props.softwareProvider && (
          <div className={styles.exportTips}>
            {props.softwareProvider.value !== templateTypes.INTUITPROSERIES &&
              props.softwareProvider.value !== templateTypes.DRAKE && (
                <>
                  <div className={styles.exportTipsTitle}>Export Tips</div>
                  {getSoftwareProviderTips(props.softwareProvider)}
                </>
              )}
          </div>
        )}
      </div>
    </>
  );

  function handleOptionSelected(option) {
    dispatch({ type: 'set_software_provider', payload: option });
    dispatch({ type: 'set_contact_type', payload: null });
  }
}

function getSoftwareProviderTips(template) {
  return softwareProviderTips[template.key]
    ? softwareProviderTips[template.key]
    : null;
}
