import React from 'react';
import PropTypes from 'prop-types';
import styles from './template-mapping-option.styles.css';
import {a} from 'kremling';

TemplateMappingOption.propTypes = {
  template: PropTypes.object,
  selectedTemplate: PropTypes.object,
  setSelectedTemplate: PropTypes.func.isRequired,
}

export default function TemplateMappingOption(props) {
  return (
    <div className={a('cps-well__light cps-padding-16', styles.option)}>
      <div className={`${styles.optionLeft}`}>
        <label className="cps-checkbox">
          <input
            type="checkbox"
            checked={props.selectedTemplate ? props.selectedTemplate.id === props.template.id : false}
            onChange={() => props.setSelectedTemplate(props.template)}
            data-checked={props.selectedTemplate ? props.selectedTemplate.id === props.template.id : false}
            data-testid={`${props.template.name}_checkbox`}
          />
          <span>{props.template.name}</span>
        </label>
      </div>
      <div className="cps-medium-gray">
        {renderMatchPercentage(props.template)}
      </div>
    </div>
  );
}

function renderMatchPercentage(template) {
  return template.columns_total ? `${calculateMatchPercentage(template)}% match` : null;
}

function calculateMatchPercentage(template) {
  return Math.round(template.columns_matched / template.columns_total * 100);
}
