import React from 'react';
import PropTypes from 'prop-types';
import styles from './uploading-csv.styles.css';
import {CprLoader} from 'canopy-styleguide!sofe';

UploadingCsv.propTypes = {
  csvFile: PropTypes.object.isRequired,
}

export default function UploadingCsv(props) {
  return (
    <div
      className={styles.container}
      data-testid="uploading-csv.component"
    >
      <div className={styles.fileInfo}>
        <img
          src="https://cdn.canopytax.com/images/csv.svg" 
          alt="CSV file"
        />
        <div className={styles.fileName}>
          {props.csvFile.name}
        </div>
        <CprLoader
          color="var(--cps-color-primary)"
        />
      </div>
    </div>
  )
}