import React from 'react';

export default function DrakeTips(props) {
  return (
    <ul>
      <li>
        For detailed instructions on exporting your records from Drake, please
        refer to their article{' '}
        <a
          href="http://kb.drakesoftware.com/Site/Browse/Export-Report-to-Excel-or-CSV-Format"
          target="_blank"
          rel="noopener noreferrer"
        >
          Export Report to Excel or CSV Format
        </a>
        .
      </li>
      <li>
        You may need to <b>create a Custom Report</b> in order to pre-select all
        of the information that you wish to export.
      </li>
      <li>
        When you export your report, <b>there are often two extra rows</b> at
        the top for a title. You will need to delete these so that the column
        headers display in row 1.
      </li>
      <li>
        Once you have exported the CSV file, you will need to open the file in
        Excel and then save it as a CSV file again. If you have the option, it
        is best to save it as a <b>CSV UTF-8 file</b>.
      </li>
    </ul>
  );
}
