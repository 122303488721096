exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 4.7rem;\n  border-bottom: 0.1rem solid var(--cps-color-athens);\n  padding: 0 1.2rem;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__titleGroup {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__title {\n  margin-left: 1.2rem;\n  font-size: 1.6rem;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__body {\n  padding: 0 1.2rem;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__nameSection {\n  padding-top: 1.6rem;\n  padding-bottom: 0.6rem;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__footer {\n  padding: 1.2rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-wrap: nowrap;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__footerLeft {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: nowrap;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__footerRight {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  flex-wrap: nowrap;\n}", ""]);

// Exports
exports.locals = {
	"header": "src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__header",
	"titleGroup": "src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__titleGroup",
	"title": "src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__title",
	"body": "src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__body",
	"nameSection": "src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__nameSection",
	"footer": "src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__footer",
	"footerLeft": "src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__footerLeft",
	"footerRight": "src-components-csv-data-column-canopy-attributes-dropdown-custom-field-page-custom-field-page-styles__footerRight"
};