import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import styles from './contact-type-selector.styles.css';
import {CprButton, CprIcon} from 'canopy-styleguide!sofe';
import {a} from 'kremling';
import {ImporterDispatch} from 'src/importer.context';

ContactTypeSelector.propTypes = {
  contactType: PropTypes.string,
}

export default function ContactTypeSelector(props) {
  const dispatch = useContext(ImporterDispatch);

  return (
    <>
      <div className={styles.title}>
        Select the type of contact you are importing
      </div>
      <div className={styles.subtitle}>
        This will help us to make your .csv import process go smoothly
      </div>
      <div className={styles.container}>
        <CprButton
          type='button'
          className={a(styles.button).m(styles.selectedButton, props.contactType === "individuals")}
          onClick={() => dispatch({type: "set_contact_type", payload: "individuals"})}
        > 
          <div className={styles.buttonFlex}>
            <CprIcon name="person" />
            <div className={styles.buttonText}>Individuals</div>
            {props.contactType === "individuals" && (
              <CprIcon
                name="checkmark-large"
                className={'cps-color-primary'}
                data-testid="individuals-checkmark"
              />
            )}
          </div>
        </CprButton>
        <CprButton
          type='button'
          className={a(styles.button).m(styles.selectedButton, props.contactType === "businesses")}
          onClick={() => dispatch({type: "set_contact_type", payload: "businesses"})}
        >
          <div className={styles.buttonFlex}>
            <CprIcon name="misc-buildings" />
            <div className={styles.buttonText}>Businesses</div>
            {props.contactType === "businesses" && (
              <CprIcon
                name="checkmark-large"
                className={'cps-color-primary'}
                data-testid="businesses-checkmark"
              />
            )}
          </div>
        </CprButton>
      </div>
    </>
  )
}