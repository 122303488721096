import React, {useRef, useReducer, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './import-contacts-modal.styles.css';
import {importContactsReducer, pages, mapCanopyFieldsFromApi, mapCustomFieldsFromApi, mapDataColumnsToFieldMappingsApi, mapDataColumnsToNewCustomFieldsApi, updateFieldUsages, createRelevantFields} from './import-contacts-modal.helper';
import FileUploadPage from './pages/file-upload/file-upload-page.component';
import ImportMappingPage from './pages/import-mapping/import-mapping-page.component';
import {getCanopyFields, getCustomFields, postImportJob} from 'src/mapping-resource.js';
import {ImporterDispatch} from 'src/importer.context.js';
import {catchAsyncStacktrace} from 'auto-trace';
import {noop} from 'lodash';
import {finalize} from 'rxjs/operators';
import AbortConfirmationModal from './modals/abort-confirmation-modal/abort-confirmation-modal.component';
import useFocusTrap from 'src/hooks/use-focus-trap.hook';

ImportContactsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export function ImportContactsModal(props) {
  const modalRef = useRef(null);
  const [state, dispatch] = useReducer(importContactsReducer, {
    softwareProvider: null,
    contactType: null,
    shouldHideSpouses: false,
    csvFile: null,
    page: pages.FILE_UPLOAD,
    dataColumns: null,
    apiCanopyFields: null,
    apiCustomFields: null,
    relevantCanopyFields: [],
    relevantCustomFields: [],
    canopyFields: [],
    customFields: [],
    totalRowCount: null,
    mappingTemplates: null,
    shouldPostImportJob: false,
    sourceId: null,
    showAbortModal: false,
    newCustomFields: [],
  })

  useFocusTrap(modalRef);
  useCanopyFieldsResource();
  useCustomFieldsResource();
  useRelevantCanopyFieldsCreator();
  useRelevantCustomFieldsCreator();
  useFieldUsagesCalculator();
  useImportJobPost();

  return (
    state ? (
      <div
        ref={modalRef}
        className="cps-accessible-important"
      >
        <ImporterDispatch.Provider value={dispatch}>
          {state.showAbortModal && (
            <AbortConfirmationModal
              confirm={props.closeModal}
              cancel={() => dispatch({type: 'set_show_abort_modal', payload: false})}
            />
          )}
          <div className={styles.modalContent}>
            {state.page === pages.FILE_UPLOAD && (
              <FileUploadPage
                softwareProvider={state.softwareProvider}
                contactType={state.contactType}
                shouldHideSpouses={state.shouldHideSpouses}
                csvFile={state.csvFile}
                uploadState={state.uploadState}
                canProceed={!!state.apiCanopyFields && !!state.apiCustomFields && !!state.dataColumns}
              />
            )}
            {state.page === pages.IMPORT_MAPPING && (
              <ImportMappingPage
                closeModal={props.closeModal}
                mappingTemplates={state.mappingTemplates}
                dataColumns={state.dataColumns}
                canopyFields={state.canopyFields}
                customFields={state.customFields}
                totalRowCount={state.totalRowCount}
              />
            )}
          </div>
        </ImporterDispatch.Provider>
      </div>
    ) : null
  )

  function useRelevantCanopyFieldsCreator() {
    useEffect(() => {
      if (state.apiCanopyFields) {
        dispatch({type: 'set_relevant_canopy_fields', payload: createRelevantFields(state.apiCanopyFields, state.contactType, state.shouldHideSpouses)})
      }
    }, [state.apiCanopyFields, state.contactType, state.shouldHideSpouses])
  }

  function useRelevantCustomFieldsCreator() {
    useEffect(() => {
      if (state.apiCustomFields) {
        dispatch({type: 'set_relevant_custom_fields', payload: createRelevantFields([...state.apiCustomFields, ...state.newCustomFields], state.contactType, state.shouldHideSpouses)})
      }
    }, [state.apiCustomFields, state.newCustomFields, state.contactType, state.shouldHideSpouses])
  }

  function useFieldUsagesCalculator() {
    useEffect(() => {
      if (state.dataColumns) {
        dispatch({type: 'set_fields', payload: {
          canopyFields: updateFieldUsages(state.relevantCanopyFields, state.dataColumns),
          customFields: updateFieldUsages(state.relevantCustomFields, state.dataColumns),
        }})
      }
    }, [state.relevantCanopyFields, state.relevantCustomFields, state.dataColumns])
  }

  function useCanopyFieldsResource() {
    useEffect(() => {
      const subscription = getCanopyFields().subscribe(
        canopyFields => {
          dispatch({type: 'set_api_canopy_fields', payload: mapCanopyFieldsFromApi(canopyFields)})
        },
        catchAsyncStacktrace()
      )

      return () => subscription.unsubscribe();
    }, [])
  }

  function useCustomFieldsResource() {
    useEffect(() => {
      const subscription = getCustomFields().subscribe(
        customFields => {
          dispatch({type: 'set_api_custom_fields', payload: mapCustomFieldsFromApi(customFields)})
        },
        catchAsyncStacktrace()
      )

      return () => subscription.unsubscribe();
    }, [])
  }

  function useImportJobPost() {
    useEffect(() => {
      if (state.shouldPostImportJob && state.sourceId && state.dataColumns) {
        const subscription = postImportJob(
          state.sourceId,
          mapDataColumnsToFieldMappingsApi(state.dataColumns).filter(field => field.to),
          state.shouldHideSpouses,
          mapDataColumnsToNewCustomFieldsApi(state.dataColumns),
        ).pipe(
          finalize(() => {
            dispatch({type: 'set_should_post_import_job', payload: false})
          })
        ).subscribe(
          noop,
          catchAsyncStacktrace()
        )

        return () => subscription.unsubscribe();
      }
    }, [state.shouldPostImportJob, state.sourceId, state.dataColumns])
  }
}
