exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-pages-file-upload-file-upload-page-styles__headerContainer {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 2.4rem;\n}\n\n.src-pages-file-upload-file-upload-page-styles__headerTitle {\n  font-size: 2.4rem;\n}\n\n.src-pages-file-upload-file-upload-page-styles__card {\n  margin-top: 5.6rem;\n  width: 800px;\n}\n\n\n/* section related max-height values are used primarily to just show a growing transition effect */\n.src-pages-file-upload-file-upload-page-styles__section {\n  margin: 2.4rem 88px;\n}\n\n.src-pages-file-upload-file-upload-page-styles__section.src-pages-file-upload-file-upload-page-styles__animateOpen {\n  animation-name: src-pages-file-upload-file-upload-page-styles__grow-open;\n  animation-duration: 1s;\n  animation-timing-function: linear;\n}\n\n.src-pages-file-upload-file-upload-page-styles__exportTipsTitle {\n  font-weight: 600;\n  padding: 0.8rem 0 0 0;\n}\n\n.src-pages-file-upload-file-upload-page-styles__hr {\n  margin: 0 0 2.4rem 0;\n}\n\n.src-pages-file-upload-file-upload-page-styles__footer {\n  display: flex;\n  justify-content: flex-end;\n  padding: 1.6rem 0 0 0;\n}\n\n@keyframes src-pages-file-upload-file-upload-page-styles__grow-open {\n  0% {\n    max-height: 0;\n    overflow: hidden;\n  }\n  99.99% {\n    overflow: hidden;\n  }\n  100% {\n    max-height: 500px;\n  }\n}", ""]);

// Exports
exports.locals = {
	"headerContainer": "src-pages-file-upload-file-upload-page-styles__headerContainer",
	"headerTitle": "src-pages-file-upload-file-upload-page-styles__headerTitle",
	"card": "src-pages-file-upload-file-upload-page-styles__card",
	"section": "src-pages-file-upload-file-upload-page-styles__section",
	"animateOpen": "src-pages-file-upload-file-upload-page-styles__animateOpen",
	"grow-open": "src-pages-file-upload-file-upload-page-styles__grow-open",
	"exportTipsTitle": "src-pages-file-upload-file-upload-page-styles__exportTipsTitle",
	"hr": "src-pages-file-upload-file-upload-page-styles__hr",
	"footer": "src-pages-file-upload-file-upload-page-styles__footer"
};