import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import styles from './uploaded-csv.styles.css';
import {CprIcon, CprButtonIcon} from 'canopy-styleguide!sofe';
import {ImporterDispatch} from 'src/importer.context';

UploadedCsv.propTypes = {
  csvFile: PropTypes.object.isRequired,
}

export default function UploadedCsv(props) {
  const dispatch = useContext(ImporterDispatch);

  return (
    <div
      className={styles.container}
      data-testid="uploaded-csv.component"
    >
      <div className={styles.fileInfo}>
        <img
          src="https://cdn.canopytax.com/images/csv.svg" 
          alt="CSV file"
        />
        <div>{props.csvFile.name}</div>
        <CprIcon name="checkmark-circle-open-small" className="cps-color-primary" />
      </div>
      <div className={styles.removeContainer}>
        <CprButtonIcon
          icon="close-large"
          ariaText="Remove CSV"
          onClick={() => removeCsvFile()}
          data-testid="remove-csv-icon"
        />
      </div>
    </div>
  )

  function removeCsvFile() {
    dispatch({type: 'reset_csv_upload'});
  }
}