import { findIndex } from 'lodash';

export const displayStates = {
  CONTINUE_OR_ABORT: "continue-or-abort",
  PROCESSING_IMPORT: "processing-import",
  SELECT_TEMPLATE: "select-template",
  HOW_TO_EXPORT: "how-to-export",
  UPLOAD: "upload",
  MAP: "map",
  REVIEW: "review",
  COMPLETE: "complete",
}

export const templateTypes = {
  LACERTE: 'LaCerte',
  ULTRATAX: 'UltraTax',
  INTUITPROSERIES: 'Intuit ProSeries',
  DRAKE: 'Drake',
  ATX: 'ATX',
  TAXWISE: 'TaxWise',
  OTHER: 'Other',
}

export function sortSourceItems(sourceColumns, mappingFields, destinationColumns) {
  const assignedItems = [];
  const unassignedItems = [];

  //Sort the headers into assigned/unassigned lists and build props
  sourceColumns.forEach((item, index) => {
    const itemData = { title: item, index };
    const indexWithinMapping = findIndex(mappingFields, field => field.from === item);

    itemData.hasBeenMapped = !!(~indexWithinMapping && mappingFields[indexWithinMapping].to);
    itemData.hasBeenSkipped = !!(~indexWithinMapping && !mappingFields[indexWithinMapping].to);

    if (itemData.hasBeenMapped) {
      const foundColumn = destinationColumns.find(column => column.name === mappingFields[indexWithinMapping].to);
      itemData.destinationTitle = foundColumn ? foundColumn.label : '';
    } else {
      itemData.destinationTitle = '';
    }

    itemData.hasBeenMapped || itemData.hasBeenSkipped ? assignedItems.push(itemData) : unassignedItems.push(itemData);
  })
  return { assignedItems, unassignedItems };
}
