exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-modals-template-mapping-modal-template-mapping-modal-styles__body {\n  overflow-y: auto;\n  max-height: calc(100vh - 500px);\n}\n\n.src-modals-template-mapping-modal-template-mapping-modal-styles__templatesContainer {\n  margin: 2.4rem 0;\n}\n\n.src-modals-template-mapping-modal-template-mapping-modal-styles__hr {\n  margin: 0 0 2.4rem 0;\n}\n\n.src-modals-template-mapping-modal-template-mapping-modal-styles__footer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-top: 0.1rem solid var(--cps-color-silver);\n}", ""]);

// Exports
exports.locals = {
	"body": "src-modals-template-mapping-modal-template-mapping-modal-styles__body",
	"templatesContainer": "src-modals-template-mapping-modal-template-mapping-modal-styles__templatesContainer",
	"hr": "src-modals-template-mapping-modal-template-mapping-modal-styles__hr",
	"footer": "src-modals-template-mapping-modal-template-mapping-modal-styles__footer"
};