import React, {useContext, useState} from "react"
import PropTypes from 'prop-types';
import {ImporterDispatch} from 'src/importer.context.js'
import styles from './custom-field-page.styles.css';
import {CprButton, CprIcon, CprButtonIcon} from 'canopy-styleguide!sofe';
import {mapNewCustomField} from 'src/import-contacts-modal.helper';
import CustomFieldName from './custom-field-name/custom-field-name.component';
import CustomFieldType from "./custom-field-type/custom-field-type.component";

CustomFieldPage.propTypes = {
  name: PropTypes.string,
  type: PropTypes.object,
  canopyFields: PropTypes.array.isRequired,
  customFields: PropTypes.array.isRequired,
  column: PropTypes.object.isRequired,
  setDropdownIsOpen: PropTypes.func.isRequired,
  setCreateEditNewCustomFieldProps: PropTypes.func.isRequired,
}

CustomFieldPage.defaultProps = {
  name: '',
  type: null,
}

export default function CustomFieldPage(props) {
  const dispatch = useContext(ImporterDispatch)
  const [field, setField] = useState({
    name: props.name,
    type: props.type,
  })
  const [statuses, setStatuses] = useState({
    name: null,
    type: null,
  })
  const [errors, setErrors] = useState({
    name: null,
    type: null,
  })

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.titleGroup}>
          <CprIcon name="misc-text-input" />
          <span className={styles.title}>Custom Field</span>
        </div>
        <CprButtonIcon
          icon="close-small"
          ariaText={`Cancel custom field ${props.name ? 'changes' : ''}`}
          onClick={handleCancelCustomFieldClick}
        />
      </div>
      <div className={styles.body}>
        <div className={styles.nameSection}>
          <CustomFieldName
            customFields={props.customFields}
            value={field.name}
            setValue={value => updateField({name: value})}
            status={statuses.name}
            setStatus={status => updateStatuses({name: status})}
            error={errors.name}
            setError={error => updateErrors({name: error})}
            originalValue={props.name}
          />
        </div>
        <div>
          <CustomFieldType
            value={field.type}
            setValue={value => updateField({type: value})}
            error={errors.type}
            setError={error => updateErrors({type: error})}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerLeft}>
          <CprButton
            actionType="primary"
            onClick={handleFinishCustomFieldClick}
            disabled={!isValid()}
          >
            {props.name ? 'Save' : 'Add'}
          </CprButton>
          <CprButton
            actionType="flat"
            onClick={handleCancelCustomFieldClick}
          >
            Cancel
          </CprButton>
        </div>
        <div className={styles.footerRight}>
          {props.name && (
            <CprButtonIcon
              icon="crud-trash-small"
              ariaText="Delete custom field"
              tooltip="Delete"
              onClick={handleDelete}
            />
          )}
        </div>
      </div>
    </div>
  )

  function updateField(value) {
    setField(previousField => ({
      ...previousField,
      ...value,
    }))
  }

  function updateErrors(error) {
    setErrors(previousErrors => ({
      ...previousErrors,
      ...error,
    }))
  }

  function updateStatuses(status) {
    setStatuses(previousStatuses => ({
      ...previousStatuses,
      ...status,
    }))
  }

  function handleCancelCustomFieldClick(e) {
    e.nativeEvent.keepDropdownOpen = true;
    props.setCreateEditNewCustomFieldProps(null);
  }

  function handleFinishCustomFieldClick(e) {
    if (isValid()) {
      if (props.name) {
        dispatch({
          type: 'edit_new_custom_field',
          payload: {
            previousName: props.name,
            newField: mapNewCustomField(field),
          },
        })
      } else {
        dispatch({
          type: 'add_new_custom_field',
          payload: {
            ...props.column,
            field: mapNewCustomField(field),
          },
        })
      }

      props.setDropdownIsOpen(false);
    }
  }

  function handleDelete(e) {
    dispatch({
      type: 'delete_new_custom_field',
      payload: field,
    })

    e.nativeEvent.keepDropdownOpen = true;
    props.setCreateEditNewCustomFieldProps(null);
  }

  function isValid() {
    return hasChanged() && !hasErrors() && !hasStatuses() && hasValues() && hasTrimmedName();
  }

  function hasChanged() {
    return field.name !== props.name || field.type !== props.type;
  }

  function hasErrors() {
    return Object.values(errors).some(error => !!error)
  }

  function hasStatuses() {
    return Object.values(statuses).some(status => !!status)
  }

  function hasValues() {
    return Object.values(field).every(value => !!value)
  }

  function hasTrimmedName() {
    return field.name && !!field.name.trim();
  }
}