import React, {useContext, useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {a} from 'kremling';
import {CprButton, CprButtonIcon} from 'canopy-styleguide!sofe';
import styles from './import-mapping-page.styles.css';
import PageHeader from '../../components/page-header/page-header.component';
import CsvDataColumn from '../../components/csv-data-column/csv-data-column.component.js';
import TemplateMappingModal from 'src/modals/template-mapping-modal/template-mapping-modal.component';
import {sortBy} from 'lodash';
import {ImporterDispatch} from 'src/importer.context';
import {doNotImportOption} from '../../import-contacts-modal.helper';
import FinalizeImportModal from '../../modals/finalize-import-modal/finalize-import-modal.component';

ImportMappingPage.propTypes = {
  closeModal: PropTypes.func.isRequired,
  mappingTemplates: PropTypes.array.isRequired,
  dataColumns: PropTypes.array.isRequired,
  canopyFields: PropTypes.array.isRequired,
  customFields: PropTypes.array.isRequired,
  totalRowCount: PropTypes.number.isRequired,
}

export default function ImportMappingPage(props) {
  const dispatch = useContext(ImporterDispatch);
  const bodyRef = useRef(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [finalizeModalIsOpen, setFinalizeModalIsOpen] = useState(false);
  const [fixedLeft, setFixedLeft] = useState(0);

  const templates = sortBy(props.mappingTemplates.filter(template => template.visible), 'columns_matched').reverse();
  const mappedDataColumns = props.dataColumns.filter(column => column.field);

  useSelectedTemplate();
  useBodyScrollUpdate();

  return (
    <>
      {templates.length && !selectedTemplate ? (
        <TemplateMappingModal
          templates={templates}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
        ) : null
      }
      {finalizeModalIsOpen && (
        <FinalizeImportModal
          closeModal={props.closeModal}
          dataColumns={props.dataColumns}
        />
      )}
      <PageHeader>
        <div className={styles.headerInfo}>
          <span><span className="cps-wt-bold">{mappedDataColumns.length} out of {props.dataColumns.length}</span> have been mapped or dismissed as do not import.</span>
        </div>
        <div className={styles.headerContainer}>
          <div className={styles.headerTitle}>
            Contact Import
          </div>
          <div className={styles.headerButtons}>
            <div className={styles.validateButton}>
              <CprButton
                actionType="primary"
                disabled={!isMappingDone()}
                onClick={() => isMappingDone() && handleValidateAndImportClick()}
              >
                Validate and Import
              </CprButton>
            </div>
            <CprButtonIcon
              icon="close-large"
              ariaText="Cancel importing contacts"
              customClass="cps-color-bumble"
              onClick={() => dispatch({type: 'set_show_abort_modal', payload: true})}
              data-testid="close-button-icon"
            />
          </div>
        </div>
      </PageHeader>
      <div className={a("cps-card", styles.card)}>
        <div
          ref={bodyRef}
          className={styles.body}
        >
          <div
            className={styles.fixed}
            style={{left: `${fixedLeft}px`}}
          >
            <div>
              <div className={styles.fixedTitle}>
                CSV Headers
              </div>
              <div>
                These are the column headers of your CSV file.  They are just for your reference and will not be imported into Canopy.
              </div>
            </div>
            <div className={styles.fixedCanopyAttributeSection}>
              <hr />
              <div className={a(styles.fixedTitle, styles.canopyAttributesText)}>
                Canopy Attributes
              </div>
              <div>
                Select the Canopy attribute that best aligns with your CSV column header listed above.
              </div>
              <div>
                <span>Create a <span className="cps-wt-bold">custom field</span> if there are no Canopy attributes that match your header or select <span className="cps-wt-bold">DO NOT IMPORT</span>.</span>
              </div>
            </div>
            <div className={styles.fixedCsvDataSection}>
              <hr />
            </div>
          </div>
          <div className={styles.dataColumnsContainer}>
            <div className={styles.dataColumns} style={{width: `${props.dataColumns.length * (188 + 16)}px`}}>
              {props.dataColumns.map((column, index) =>
                <CsvDataColumn
                  key={column.header}
                  column={column}
                  index={index}
                  canopyFields={props.canopyFields}
                  customFields={props.customFields}
                  totalRowCount={props.totalRowCount}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )

  function useSelectedTemplate() {
    useEffect(() => {
      if (selectedTemplate) {
        const mappedDataColumns = props.dataColumns.map(dataColumn => {
          const templateFieldMapping = selectedTemplate.fields && selectedTemplate.fields.find(field => field.from === dataColumn.header);
          if (templateFieldMapping) {
            if (templateFieldMapping.to) {
              const canopyFieldMatch = props.canopyFields.find(field => field.name === templateFieldMapping.to);
              if (canopyFieldMatch) {
                // if 'to' matches a canopy field's '.name' use that first
                return {...dataColumn, field: canopyFieldMatch}
              } else {
                const customFieldMatch = props.customFields.find(field => field.name === templateFieldMapping.to);
                if (customFieldMatch) {
                  // else if 'to' matches a custom field's '.name' use that second
                  return {...dataColumn, field: customFieldMatch}
                }
              }
            } else {
              return {...dataColumn, field: doNotImportOption}
            }
          }

          return dataColumn;
        })

        dispatch({type: 'set_data_columns', payload: mappedDataColumns});
      }
    }, [selectedTemplate])
  }

  function useBodyScrollUpdate() {
    useEffect(() => {
      if (bodyRef && bodyRef.current) {
        const bodyEl = bodyRef.current // Copy to var so that the ref in the cleanup targets the correct node
        bodyEl.addEventListener('scroll', updateFixedLeft)

        return () => bodyEl.removeEventListener('scroll', updateFixedLeft)
      }
    })

    function updateFixedLeft() {
      setFixedLeft(bodyRef.current.scrollLeft);
    }
  }

  function isMappingDone() {
    return allDataColumnsHaveField() && allRequiredFieldsAreUsed()
  }

  function allDataColumnsHaveField() {
    return props.dataColumns.every(dataColumn => dataColumn.field);
  }

  function allRequiredFieldsAreUsed() {
    return props.canopyFields.filter(field => field.isRequired).every(requiredField => requiredField.usedCount > 0)
      && props.customFields.filter(field => field.isRequired).every(requiredField => requiredField.usedCount > 0)
  }

  function handleValidateAndImportClick() {
    dispatch({type: 'set_should_post_import_job', payload: true});
    setFinalizeModalIsOpen(true);
  }
}
