import React from "react";
import PropTypes from 'prop-types';
import {CprButtonIcon} from 'canopy-styleguide!sofe';
import {a} from 'kremling';
import styles from './attributes-item.styles.css';
import {zip, flatten, noop} from 'lodash';

AttributesItem.propTypes = {
  column: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  listIndex: PropTypes.number.isRequired,
  optionFocusIndex: PropTypes.number.isRequired,
  setOptionFocusIndex: PropTypes.func.isRequired,
  selectField: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  setCreateEditNewCustomFieldProps: PropTypes.func,
  customClassName: PropTypes.string,
}

AttributesItem.defaultProps = {
  searchValue: '',
  setCreateEditNewcustomFieldProps: noop,
  customClassName: '',
}

export default function AttributesItem(props) {
  return (
    <li
      id={`import-contacts_attributes-item_${props.listIndex}`}
      className={
        a(styles.option)
        .m(styles.cannotSelect, props.field.usedCount && !props.field.allowMultiple)
        .m(styles.isFocused, props.optionFocusIndex === props.listIndex)
        .m(props.customClassName, !!props.customClassName)
      }
      onMouseOver={() => props.setOptionFocusIndex(props.listIndex)}
      onClick={() => props.selectField(props.field)}
      role="option"
      aria-selected={getAriaSelected(props.field)}
    >
      <div className={styles.left}>
        {renderHighlightedJsx(props.field.displayLabel, props.searchValue)}
        {props.field.isNewCustomField && (
          <CprButtonIcon
            icon="crud-pencil"
            ariaText={`Edit ${props.field.displayLabel} custom field`}
            customClass={styles.editButtonIcon}
            onClick={handleEditClick}
            onKeyDown={handleEditKeyDown}
            onFocus={() => props.setOptionFocusIndex(props.listIndex)}
          />
        )}
        {props.field.isRequired ? (
          <>
            <span className={styles.requiredText}>(Required)</span>
            <span className={styles.requiredAsterisk}>*</span>
          </>
        ) : null}
      </div>
      <div className={styles.right}>
        <div className={styles.isUsedText}>
          {props.field.usedCount > 0 ? 'In use' : null}
        </div>
      </div>
    </li>
  )

  function handleEditClick(e) {
    e.stopPropagation();
    e.nativeEvent.keepDropdownOpen = true;
    props.setCreateEditNewCustomFieldProps({name: props.field.name, type: props.field.type});
  }

  function handleEditKeyDown(e) {
    if (e.key === 'Enter') {
      e.stopPropagation();
      props.setCreateEditNewCustomFieldProps({name: props.field.name, type: props.field.type});
    }
  }

  function getAriaSelected(field) {
    if (field.usedCount && !field.allowMultiple) {
      return (props.column.field && props.column.field.name === field.name) ? true : null;
    } else {
      return props.column.field ? props.column.field.name === field.name : false;
    }
  }
}

function renderHighlightedJsx(text, searchValue) {
  let trimmedSearchValue = searchValue.trim();

  if (trimmedSearchValue.length) {
    const replacedSearchValue = trimmedSearchValue.replace(/[^a-zA-Z0-9\ ]/g, '')
    const searchValueRegex = new RegExp(replacedSearchValue, 'gi');

    const matches = text.match(searchValueRegex) || [];
    const nonMatches = text.split(searchValueRegex);
    return composeHighlightJsx(matches, nonMatches);
  } else {
    return text;
  }
}

function composeHighlightJsx(matches, nonMatches) {
  const matchesJsx = matches.map((match, index) => <span key={`m_${index}`} className={styles.highlight}>{match}</span>);
  const nonMatchesJsx = nonMatches.map((nonMatch, index) => <span key={`n_${index}`}>{nonMatch}</span>);
  
  const zippedArray = zip(nonMatchesJsx, matchesJsx);
  const flattenedArray = flatten(zippedArray);
  flattenedArray.pop();
  return flattenedArray;
}