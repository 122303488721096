import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import styles from './upload-csv-error.styles.css';
import {CprButton} from 'canopy-styleguide!sofe';
import {ImporterDispatch} from 'src/importer.context';

UploadCsvError.propTypes = {
  csvFile: PropTypes.object.isRequired,
}

export default function UploadCsvError(props) {
  const dispatch = useContext(ImporterDispatch);

  return (
    <div className={styles.container}>
      <div className={styles.fileInfo}>
        <img
          src="https://cdn.canopytax.com/images/document-exclamation-point.svg" 
          alt="CSV file upload error"
        />
        <div>There was a problem working with <b>{props.csvFile.name}</b>. Make sure the file is in a CSV format.</div>
        <CprButton
          actionType="flat"
          onClick={handleRetryClick}
        >
          Retry
        </CprButton>
      </div>
    </div>
  )

  function handleRetryClick() {
    dispatch({type: 'reset_csv_upload'})
  }
}