import React from 'react';
import PropTypes from 'prop-types';
import {CprShortSingleSelect} from 'canopy-styleguide!sofe';
import {CustomFieldTypes} from 'src/custom-fields.helper';
import styles from './custom-field-type.styles.css';
import {featureEnabled} from 'feature-toggles!sofe';

CustomFieldType.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func.isRequired,
}

export default function CustomFieldType(props) {
  const typeOptions = Object.values(CustomFieldTypes);

  return (
    <div>
      <CprShortSingleSelect
        labelText="Field type"
        initialSelection={props.value ? props.value.key : null}
        placeholderText="Select field type..."
        options={typeOptions}
        optionSelected={handleSelection}
        width="100%"
        listWidth="100%"
      />
      <div className={styles.explanation}>
        {renderTypeExplanation()}
      </div>
    </div>
  )

  function renderTypeExplanation() {
    if (props.value === CustomFieldTypes.text) {
      return <span>A single line of text.</span>
    } else if (props.value === CustomFieldTypes.date) {
      return <span>Enter a date (e.g. 11/12/2016) or pick one from a calendar.</span>
    } else if (props.value === CustomFieldTypes.dropdown) {
      return <span>Select a single value from a list of options.</span>
    } else if (props.value === CustomFieldTypes.multiselect) {
      return <span>Select one or more values from a list of options.</span>
    }
  }

  function handleSelection(type) {
    props.setValue(type);
  }
}
