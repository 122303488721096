import React from 'react';

export default function LacerteTips(props) {
  return (
    <ul>
      <li>For detailed instructions on exporting your records from Lacerte, please refer to their article <a href="https://accountants-community.intuit.com/articles/1606606-exporting-the-lacerte-client-list-to-a-spreadsheet" target="_blank" rel="noopener noreferrer">Exporting the Lacerte Client List to a Spreadsheet</a>.</li>
      <li>In Lacerte you can select all clients by selecting the first client on the list and then clicking <b>Shift + End</b>.</li>
      <li>To correctly import your contact names you will need to change the default settings to <b>export first and last names as separate columns</b>.</li>
      <li>When opening your file in Excel be sure to save it as a CSV file. The best format is <b>CSV UTF-8</b>.</li>
    </ul>
  )
}