import canopyUrls from 'canopy-urls!sofe';
import {fetchAsObservable} from 'fetcher!sofe';
import {removeUnusedFields} from './mapping-resource-helper.js';
import {pluck} from 'rxjs/operators';

export function uploadFile(file) {

  let formData = new FormData();
  formData.append('file', file);

  const requestObj = {
    method: 'POST',
    body: formData,
  };

  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/import/sources`, requestObj);
}

export function saveMapping(mapping) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/import/mappings`, { method: 'POST', headers: new Headers({ 'Content-Type': 'application/json' }), body: `${JSON.stringify({ mapping_template: mapping })}` });
}

export function getMapping(id) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/import/mappings`, { headers: new Headers({ 'Content-Type': 'application/json' }), body: { id } });
}

export function deleteMapping(id) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/import/mappings/${id}`, { method: 'DELETE' });
}

export function submitImportJob(mapping, source_columns) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/import/imports`, { method: 'POST', body: `${JSON.stringify({ imports: removeUnusedFields(mapping, source_columns) })}` });
}

export function getCRMColumns() {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/import/fields`);
}

export function getLastFiveImports() {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/import/imports`);
}

export function getCanopyFields() {
  return fetchAsObservable(`/api/import/fields`).pipe(pluck('fields'))
}

export function getCustomFields() {
  return fetchAsObservable('/api/custom_fields').pipe(pluck('custom_fields'))
}

export function postCsvFile(file) {
  let body = new FormData();
  body.append('file', file);

  return fetchAsObservable(`/api/import/sources`, {
    method: 'POST',
    body
  })
}

export function postMappingTemplate(name, fieldMappings) {
  const bodyObj = {
    mapping_template: {
      name,
      fields: fieldMappings,
    }
  }

  return fetchAsObservable(`/api/import/mappings`, {
    method: 'POST',
    headers: new Headers({'Content-Type': 'application/json'}),
    body: `${JSON.stringify(bodyObj)}`
  })
}

export function deleteMappingTemplate(id) {
  return fetchAsObservable(`/api/import/mappings/${id}`, {
    method: 'DELETE'
  })
}

export function postImportJob(sourceId, fieldMappings, shouldHideSpouses, newCustomFields) {
  const bodyObj = {
    imports: {
      source: sourceId,
      fields: fieldMappings,
      hide_spouses: shouldHideSpouses,
      new_custom_fields: newCustomFields,
    }
  }

  return fetchAsObservable(`/api/import/imports`, {
    method: 'POST',
    body: `${JSON.stringify(bodyObj)}`
  })
}
