import React, {useContext, useRef, useState} from 'react';
import styles from './csv-selector.styles.css';
import {CprButton} from 'canopy-styleguide!sofe';
import {a} from 'kremling';
import {uploadStates} from '../csv-uploader.helper';
import {ImporterDispatch} from 'src/importer.context';

const dragStates = {
  OVER_DROP: 'over drop',
}

export default function CsvSelector(props) {
  const dispatch = useContext(ImporterDispatch);
  const fileInputRef = useRef(null);
  const [dragState, setDragState] = useState(null);

  return (
    <div
      className={a(styles.dropZone).m(styles.overDropZone, dragState === dragStates.OVER_DROP)}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <img
        src="https://cdn.canopytax.com/images/csv-upload.svg" 
        alt="upload CSV files"
        className={styles.csvImage}
      />
      <div className={styles.title}>
        Upload your contact list.
      </div>
      <div className={styles.subtitle}>
        Drag and drop your CSV file here or
      </div>
      <CprButton
        actionType='primary'
        type='button'
        onClick={() => fileInputRef.current.click()}
      > 
        Upload from computer
      </CprButton>
      <input
        ref={fileInputRef}
        style={{display: 'none'}}
        type="file"
        accept=".csv"
        onChange={handleSelectedFile}
        data-testid="file-input"
      />
    </div>
  )

  function handleSelectedFile() {
    if (fileInputRef.current.files.length) {
      handleFile(fileInputRef.current.files[0]);
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
    setDragState(dragStates.OVER_DROP);
  }

  function handleDragLeave(e) {
    e.preventDefault();
    setDragState(null);
  }

  function handleDrop(e) {
    e.preventDefault();
    
    if (e.dataTransfer.files.length) {
      handleFile(e.dataTransfer.files[0]);
    }

    setDragState(null);
  }

  function handleFile(file) {
    if (!fileAppearsAcceptable(file)) {
      dispatch({type: 'set_upload_state', payload: uploadStates.ERROR})
    }

    dispatch({type: 'set_csv_file', payload: file})
  }
}

function fileAppearsAcceptable(file) {
  return file.name && /\.csv$/.test(file.name.toLowerCase());
}