import React, {useRef, useState, useEffect} from "react"
import PropTypes from 'prop-types';
import styles from './add-custom-field-item.styles.css';
import {CprButton, CprIcon} from 'canopy-styleguide!sofe';

AddCustomFieldItem.propTypes = {
  listIndex: PropTypes.number.isRequired,
  optionFocusIndex: PropTypes.number.isRequired,
  setOptionFocusIndex: PropTypes.func.isRequired,
  handleStartAddCustomField: PropTypes.func,
  handleBlur: PropTypes.func,
}

AddCustomFieldItem.defaultProps = {
  handleBlur: () => {},
}

export default function AddCustomFieldItem(props) {
  const addCustomFieldButtonContainerRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  useCprButtonFocusAndBlur();

  return (
    <li
      id={`import-contacts_attributes-item_${props.listIndex}`}
      className={styles.option}
      role="option"
    >
      <div ref={addCustomFieldButtonContainerRef}>
        <CprButton
          actionType="flat"
          onClick={props.handleStartAddCustomField}
          onKeyDown={handleAddCustomFieldKeyDown}
          onMouseOver={() => props.setOptionFocusIndex(props.listIndex)}
          onFocus={() => props.setOptionFocusIndex(props.listIndex)}
        >
          <CprIcon name="add-circle-filled" /> Add a custom field
        </CprButton>
      </div>
    </li>
  )

  function useCprButtonFocusAndBlur() {
    useEffect(() => {
      if (addCustomFieldButtonContainerRef && addCustomFieldButtonContainerRef.current) {
        const cprButton = addCustomFieldButtonContainerRef.current.querySelector('button');
        if (props.optionFocusIndex === props.listIndex) {
          cprButton.focus();
          setIsFocused(true);
        } else {
          if (isFocused) {
            props.handleBlur();
            setIsFocused(false);
          }
        }
      }
    }, [props.optionFocusIndex, props.listIndex])
  }

  function handleAddCustomFieldKeyDown(e) {
    switch (e.key) {
      case 'Tab':
        props.setDropdownIsOpen(false);
        break;
      case 'Enter':
        e.stopPropagation();
        props.handleStartAddCustomField(); 
        break;
    }
  }
}