exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__scrollContainer {\n  position: relative;\n  padding: 0;\n  max-height: 280px; /* 7 items * 40px */\n  overflow-y: auto;\n  outline: none;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__doNotImport {\n  font-weight: 800;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__customFieldsHeader {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  min-height: 40px;\n  padding: 0 10px;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__customFieldsHeaderText {\n  min-width: 9rem;\n  margin-left: 1.2rem;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__noFieldsFoundContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin: 0 0.8rem;\n  border-bottom: 0.1rem solid var(--cps-color-athens);\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__noFieldsFoundTitle {\n  padding-top: 2.4rem;\n  font-weight: 800;\n  font-size: 1.6rem;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__noFieldsFoundSubtitle {\n  padding-bottom: 2.4rem;\n  margin-top: 0.8rem;\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"scrollContainer": "src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__scrollContainer",
	"doNotImport": "src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__doNotImport",
	"customFieldsHeader": "src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__customFieldsHeader",
	"customFieldsHeaderText": "src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__customFieldsHeaderText",
	"noFieldsFoundContainer": "src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__noFieldsFoundContainer",
	"noFieldsFoundTitle": "src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__noFieldsFoundTitle",
	"noFieldsFoundSubtitle": "src-components-csv-data-column-canopy-attributes-dropdown-attributes-page-attributes-page-styles__noFieldsFoundSubtitle"
};