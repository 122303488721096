import React, { useContext } from "react";
import PropTypes from "prop-types";
import { a } from "kremling";
import { CprButton, CprButtonIcon } from "canopy-styleguide!sofe";

import styles from "./file-upload-page.styles.css";
import { pages } from "../../import-contacts-modal.helper";
import PageHeader from "../../components/page-header/page-header.component";
import SoftwareProviderSelector from "../../components/software-provider-selector/software-provider-selector.component";
import ContactTypeSelector from "../../components/contact-type-selector/contact-type-selector.component";
import CsvUploader from "../../components/csv-uploader/csv-uploader.component";
import DrakeTips from "../../components/software-provider-selector/drake/drake-tips.component";
import IntuitProSeriesTips from "../../components/software-provider-selector/intuit/intuit-pro-series-tips.component";
import { ImporterDispatch } from "src/importer.context";
import { templateTypes } from "../../common/common.helper";

FileUploadPage.propTypes = {
  softwareProvider: PropTypes.object,
  contactType: PropTypes.string,
  shouldHideSpouses: PropTypes.bool.isRequired,
  csvFile: PropTypes.object,
  uploadState: PropTypes.string,
  canProceed: PropTypes.bool.isRequired,
};

export default function FileUploadPage(props) {
  const dispatch = useContext(ImporterDispatch);

  return (
    <>
      <PageHeader>
        <div className={styles.headerContainer}>
          <div className={styles.headerTitle}>Import Contacts</div>
          <CprButtonIcon
            icon="close-large"
            ariaText="Cancel importing contacts"
            customClass="cps-color-bumble"
            onClick={() =>
              dispatch({ type: "set_show_abort_modal", payload: true })
            }
            data-testid="close-button-icon"
          />
        </div>
      </PageHeader>
      <div className={a("cps-flexible-focus cps-card", styles.card)}>
        <div className="cps-card__header cps-subheader">
          File Upload and Tips
        </div>
        <div className="cps-card__body">
          <div className={styles.section}>
            <SoftwareProviderSelector
              softwareProvider={props.softwareProvider}
            />
          </div>
          {props.softwareProvider &&
            props.softwareProvider.value !== templateTypes.INTUITPROSERIES &&
            props.softwareProvider.value !== templateTypes.DRAKE && (
              <div className={a(styles.section, styles.animateOpen)}>
                <hr className={styles.hr} />
                <ContactTypeSelector
                  contactType={props.contactType}
                  softwareProvider={props.softwareProvider}
                />
              </div>
            )}
          {props.softwareProvider &&
            (props.softwareProvider.value === templateTypes.INTUITPROSERIES ||
              props.softwareProvider.value === templateTypes.DRAKE) && (
              <div className={a(styles.section, styles.animateOpen)}>
                <div>
                  <ContactTypeSelector
                    contactType={props.contactType}
                    softwareProvider={props.softwareProvider}
                  />
                  <div className={styles.exportTipsTitle}>Export Tips</div>
                  {props.softwareProvider.value ===
                  templateTypes.INTUITPROSERIES ? (
                    <IntuitProSeriesTips />
                  ) : (
                    <DrakeTips />
                  )}
                </div>
              </div>
            )}
          {props.contactType && (
            <div className={a(styles.section, styles.animateOpen)}>
              <CsvUploader
                csvFile={props.csvFile}
                uploadState={props.uploadState}
              />
            </div>
          )}
          {props.canProceed && (
            <div className={a("cps-card__footer", styles.footer)}>
              <CprButton
                actionType="primary"
                onClick={() =>
                  dispatch({ type: "set_page", payload: pages.IMPORT_MAPPING })
                }
              >
                Next
              </CprButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
