import {mapValues} from 'lodash';

const customFieldTypesDefinition = {
  text: 'Text input',
  date: 'Date',
  dropdown: 'Dropdown select',
  multiselect: 'Multi-select'
}

export const CustomFieldTypes = keyifyObj(customFieldTypesDefinition);

function keyifyObj(obj) {
  // example: {a: 1}   =>   {a: {key: 'a', value: 1}}
  return mapValues(obj, (value, key) => ({
    key,
    value,
  }))
}

export const customFieldNameDebounceMilliseconds = 250;