exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__button button, .src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__button button:focus, .src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__button button:hover {\n  border-radius: 5px;\n  border: 1px solid var(--cps-color-athens);\n  width: 100%;\n  height: 32px;\n  background-color: var(--cps-color-bumble);\n  padding: 0 8px 0 10px;\n  outline: none;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__button cps-button button.src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__active {\n  border: 1px solid var(--cps-color-primary);\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__left {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  overflow: hidden;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__customFieldIcon {\n  margin-right: 1rem;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__right {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__label {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}", ""]);

// Exports
exports.locals = {
	"button": "src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__button",
	"active": "src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__active",
	"left": "src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__left",
	"customFieldIcon": "src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__customFieldIcon",
	"right": "src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__right",
	"label": "src-components-csv-data-column-canopy-attributes-dropdown-canopy-attributes-button-canopy-attributes-button-styles__label"
};