import {sortBy, countBy} from 'lodash';

export const pages = {
  FILE_UPLOAD: 'file-upload-and-tips',
  IMPORT_MAPPING: 'import-mapping'
}

const softwareProviderOptionsArray = [
  'Drake',
  'Intuit ProSeries',
  'Lacerte',
  'UltraTax',
  'ATX',
  'TaxWise',
  'Other'
]

export const softwareProviderOptions = softwareProviderOptionsArray.map(option => ({key: option, value: option}))

export const doNotImportOption = {
  displayLabel: 'DO NOT IMPORT',
  name: null,
  allowMultiple: true,
}

const canopyFieldsConfig = {
  required: [
    'first_name',
    'last_name',
    'business_name',
  ],
  alwaysHidden: [
    'is_business',
  ],
  defaultHidden: [
    'spouse_work_email',
    'spouse_other_email',
    'spouse_other_phone',
    'spouse_other_phone_ext',
    'spouse_fax_phone',
    'spouse_fax_phone_ext',
    'spouse_client_since',
    'spouse_contact_owner',
    'spouse_source',
    'spouse_mailing_address_street_1',
    'spouse_mailing_address_street_2',
    'spouse_mailing_locality',
    'spouse_mailing_region',
    'spouse_mailing_postal_code',
    'spouse_mailing_country',
    'spouse_work_address_street_1',
    'spouse_work_address_street_2',
    'spouse_work_locality',
    'spouse_work_region',
    'spouse_work_postal_code',
    'spouse_work_country',
    'spouse_other_address_street_1',
    'spouse_other_address_street_2',
    'spouse_other_locality',
    'spouse_other_region',
    'spouse_other_postal_code',
    'spouse_other_country',
    'spouse_tags',
  ],
  allowMultiple: [
    'default_email',
    'work_email',
    'other_email',
    // 'default_phone',
    // 'default_phone_ext',
    'mobile_phone',
    // 'work_phone',
    // 'work_phone_ext',
    // 'other_phone',
    // 'other_phone_ext',
    // 'fax_phone',
    // 'fax_phone_ext',
    'spouse_default_email',
    'spouse_work_email',
    'spouse_other_email',
    'spouse_default_phone',
    'spouse_mobile_phone',
    // 'spouse_work_phone',
    // 'spouse_work_phone_ext',
    // 'spouse_other_phone',
    // 'spouse_other_phone_ext',
    // 'spouse_fax_phone',
    // 'spouse_fax_phone_ext',
  ],
  onlyIfShowSpouses: [
    'spouse_contact_type',
  ],
  individualDisplayLabels: {
    'tin': 'SSN',
    'default_phone': 'Home phone',
    'default_phone_ext': 'Home phone ext',
    'spouse_birthdate': 'Spouse birthday',
    'contact_owner': 'Contact owner email',
    'spouse_contact_owner': 'Spouse contact owner email',
  },
  businessDisplayLabels: {
    'tin': 'EIN',
    'contact_owner': 'Contact owner email',
  },
}

export function importContactsReducer(state, action) {
  switch (action.type) {
    case 'set_software_provider':
      return {...state, softwareProvider: action.payload}
    case 'set_contact_type':
      return {...state, contactType: action.payload}
    case 'set_csv_file':
      return {...state, csvFile: action.payload}
    case 'set_should_hide_spouses':
      return {...state, shouldHideSpouses: action.payload}
    case 'set_upload_state':
      return {...state, uploadState: action.payload}
    case 'set_api_canopy_fields':
      return {...state, apiCanopyFields: action.payload}
    case 'set_api_custom_fields':
      return {...state, apiCustomFields: action.payload}
    case 'set_relevant_canopy_fields':
      return {...state, relevantCanopyFields: action.payload}
    case 'set_relevant_custom_fields':
      return {...state, relevantCustomFields: action.payload}
    case 'complete_csv_upload':
      return {
        ...state,
        uploadState: action.payload.uploadState,
        dataColumns: action.payload.dataColumns,
        totalRowCount: action.payload.totalRowCount,
        mappingTemplates: action.payload.mappingTemplates,
        sourceId: action.payload.sourceId,
      }
    case 'reset_csv_upload':
      return {
        ...state,
        csvFile: null,
        uploadState: null,
        dataColumns: null,
        totalRowCount: null,
        mappingTemplates: null,
        sourceId: null,
      }
    case 'delete_mapping_template':
      const mappingTemplates = state.mappingTemplates.filter(template => template.id !== action.payload.id)  
      return {...state, mappingTemplates}
    case 'set_page':
      return {...state, page: action.payload}
    case 'set_data_columns':
      return {
        ...state,
        dataColumns: action.payload,
      }
    case 'set_data_column':
      return {
        ...state,
        dataColumns: setDataColumn(state.dataColumns, action.payload),
      }
    case 'set_fields':
      return {
        ...state,
        canopyFields: action.payload.canopyFields,
        customFields: action.payload.customFields,
      };
    case 'set_show_abort_modal':
      return {...state, showAbortModal: action.payload}
    case 'set_should_post_import_job':
      return {...state, shouldPostImportJob: action.payload}
    case 'add_new_custom_field':
      const newCustomFieldsAfterAdd = [...state.newCustomFields, action.payload.field];

      return {
        ...state,
        newCustomFields: newCustomFieldsAfterAdd,
        dataColumns: setDataColumn(state.dataColumns, action.payload),
      }
    case 'edit_new_custom_field':            
      // remove previous custom field
      let newCustomFieldsAfterEdit = state.newCustomFields.filter(newCustomField => newCustomField.name !== action.payload.previousName);

      // add new custom field
      newCustomFieldsAfterEdit = [...newCustomFieldsAfterEdit, action.payload.newField];

      // update data columns that were assigned previous custom field
      const dataColumnsAfterEdit = state.dataColumns.map(dataColumn => {
        if (dataColumn.field && dataColumn.field.name === action.payload.previousName) {
          return {...dataColumn, field: action.payload.newField}
        }
          
        return {...dataColumn}
      })
     
      return {
        ...state,
        newCustomFields: newCustomFieldsAfterEdit,
        dataColumns: dataColumnsAfterEdit,
      }
    case 'delete_new_custom_field':
      // remove custom field
      const newCustomFieldsAfterDelete = state.newCustomFields.filter(newCustomField => newCustomField.name !== action.payload.name);

      // remove custom field from assigned data columns
      const dataColumnsAfterDelete = state.dataColumns.map(dataColumn => {
        if (dataColumn.field && dataColumn.field.name === action.payload.name) {
          return {...dataColumn, field: null}
        }
          
        return {...dataColumn}
      })

      return {
        ...state,
        newCustomFields: newCustomFieldsAfterDelete,
        dataColumns: dataColumnsAfterDelete,
      }
    default:
      return state
  }
}

function setDataColumn(dataColumns, column) {
  return dataColumns.map(dataColumn => {
    return dataColumn.header === column.header ? {...column} : dataColumn;
  })
}

export function mapCanopyFieldsFromApi(fields) {
  const mappedFields = fields.map(field => {
    const mappedField = {
      ...field,
      isCanopyField: true,
      isIndividual: true,
      isBusiness: true,
      individualDisplayLabel: canopyFieldsConfig.individualDisplayLabels[field.name] || field.label,
      businessDisplayLabel: canopyFieldsConfig.businessDisplayLabels[field.name] || field.label,
    }

    if (field.info === 'Individual only') {
      mappedField.isBusiness = false;
    } else if (field.info === 'Business only') {
      mappedField.isIndividual = false;
    }

    if (canopyFieldsConfig.required.includes(field.name)) {
      mappedField.isRequired = true;
    }

    if (canopyFieldsConfig.defaultHidden.includes(field.name)) {
      mappedField.defaultHidden = true;
    }

    if (canopyFieldsConfig.allowMultiple.includes(field.name)) {
      mappedField.allowMultiple = true;
    }

    if (canopyFieldsConfig.onlyIfShowSpouses.includes(field.name)) {
      mappedField.onlyIfShowSpouses = true;
    }

    return mappedField;
  })
  .filter(field => !canopyFieldsConfig.alwaysHidden.includes(field.name))

  return mappedFields;
}

export function mapCustomFieldsFromApi(fields) {
  const mappedFields = fields.map(field => {
    const mappedField = {
      ...field,
      name: field.field_name,
      isCustomField: true,
      isIndividual: true,
      isBusiness: true,
      displayLabel: field.field_name,
    }

    if (field.contact_type === 'individuals') {
      mappedField.isBusiness = false;
    } else if (field.contact_type === 'businesses') {
      mappedField.isIndividual = false;
    }
    
    return mappedField;
  })

  return mappedFields;
}

export function mapNewCustomField(field) {
  const trimmedName = field.name.trim();

  const mappedField = {
    name: trimmedName,
    type: field.type,
    isNewCustomField: true,
    displayLabel: trimmedName,
    isIndividual: true,
    isBusiness: true,
  }

  return mappedField;
}

export function mapDataColumnsToFieldMappingsApi(dataColumns) {
  return dataColumns.map(dataColumn => {
    return {
      from: dataColumn.header,
      to: dataColumn.field.name,
    }
  })
}

export function mapDataColumnsToNewCustomFieldsApi(dataColumns) {
  return dataColumns.filter(dataColumn => dataColumn.field.isNewCustomField).map(dataColumn => {
    return {
      name: dataColumn.field.name,
      type: dataColumn.field.type.key,
    }
  })
}

export function updateFieldUsages(fields, dataColumns) {
  const mappedDataColumns = dataColumns.filter(dataColumn => dataColumn.field);
  const fieldUsages = countBy(mappedDataColumns, dataColumn => {
    return dataColumn.field.name;
  });

  return fields.map(field => {
    return {
      ...field,
      usedCount: fieldUsages[field.name] || 0,
    }
  })
}

function filterFields(fields, contactType, shouldHideSpouses) {
  return fields.filter(field => {
    let shouldInclude = (contactType === 'individuals' && field.isIndividual) || (contactType === 'businesses' && field.isBusiness)

    if (shouldInclude && shouldHideSpouses) {
      shouldInclude = !field.onlyIfShowSpouses;
    }

    return shouldInclude;
  });
}

function setDisplayLabels(fields, contactType) {
  return fields.map(field => ({...field, displayLabel: getDisplayLabel(field, contactType)}));
}

function getDisplayLabel(field, contactType) {
  if (field.displayLabel) {
    return field.displayLabel;
  } else {
    return contactType === 'individuals' ? field.individualDisplayLabel : field.businessDisplayLabel;
  }
}

export function createRelevantFields(fields, contactType, shouldHideSpouses) {
  const filteredFields = filterFields(fields, contactType, shouldHideSpouses);
  const filteredFieldsWithDisplayLabels = setDisplayLabels(filteredFields, contactType);

  return sortBy(filteredFieldsWithDisplayLabels, [
    'isRequired',
    field => field.displayLabel.toLowerCase(),
  ]);
}