import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './custom-field-name.styles.css';
import {a} from 'kremling';
import {CprLoader} from 'canopy-styleguide!sofe';
import {checkCustomFieldAvailability} from 'src/custom-fields.resource';
import {asyncStacktrace, catchSyncStacktrace} from 'auto-trace';
import {finalize} from 'rxjs/operators';
import {customFieldNameDebounceMilliseconds} from 'src/custom-fields.helper';

CustomFieldName.propTypes = {
  customFields: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
  status: PropTypes.string,
  setStatus: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  originalValue: PropTypes.string.isRequired,
}

CustomFieldName.defaultProps = {
  maxLength: 35,
}

export default function CustomFieldName(props) {
  const nameInputRef = useRef(null);

  useInitialFocus();
  useErrorsCheck();

  return (
    <div className={a('cps-form-group', styles.fieldNameContainer).m('cps-has-error', props.error)}>
      <label
        htmlFor="custom-field-dropdown-name"
      >
        Field name
      </label>
      <input
        id="custom-field-dropdown-name"
        ref={nameInputRef}
        placeholder="Enter name"
        value={props.value}
        onChange={handleChange}
        className="cps-form-control"
      />
      <span aria-hidden="true" className={`cps-icon-error cps-form-control-feedback ${styles.centerError}`} />
      <div className={styles.status}>
        <span className='cps-error-block'>{props.error}</span>
        {props.status ? (
          <CprLoader
            customClass={styles.loader}
            color={'var(--cps-color-green)'}
          />
        ) : null}
      </div>
    </div>
  )

  function useInitialFocus() {
    useEffect(() => {
      focus();
    }, [])
  }

  function useErrorsCheck() {
    useEffect(() => {
      let timeout;
      let subscription;
      
      const trimmedValue = props.value && props.value.trim();

      if (!trimmedValue) {
        props.setStatus(null);
        props.setError(null);
      } else {
        props.setStatus('Debouncing user input...');
        props.setError(null);

        timeout = setTimeout(() => {
          if (isDuplicateNewCustomField(trimmedValue)) {
            props.setStatus(null);
            props.setError(`"${trimmedValue}" is already created!`);
          } else {
            props.setStatus('Checking server if duplicate name...');
            
            subscription = checkCustomFieldAvailability(trimmedValue).pipe(
              finalize(() => {
                props.setStatus(null);
              })
            ).subscribe(
              response => {
                if (response.isDuplicate) {
                  props.setError(`"${trimmedValue}" already exists!`);
                } else if (response.isReserved) {
                  props.setError(`"${trimmedValue}" is unavailable!`);
                } else {
                  props.setError(null);
                }
              },
              asyncStacktrace(err =>  {
                props.setError('A server error was encountered! Please try again.');
                catchSyncStacktrace(err);
              })
            )
          }
        }, customFieldNameDebounceMilliseconds);
      }
      
      return () => {
        timeout && clearTimeout(timeout);
        subscription && subscription.unsubscribe();
      }

      function isDuplicateNewCustomField(trimmedValue) {
        if (props.originalValue && props.originalValue.toLowerCase() === trimmedValue.toLowerCase()) {
          return false;
        } else {
          return props.customFields
            .filter(field => field.isNewCustomField)
            .map(newCustomField => newCustomField.name.toLowerCase())
            .includes(trimmedValue.toLowerCase())
        }
      }
    }, [props.value])
  }

  function handleChange(e) {
    const value = e.target.value;

    if (canChange(value, props.maxLength)) {
      props.setValue(value);
    }
  }

  function focus() {
    if (nameInputRef && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }
}

function canChange(value, maxLength) {
  return value.length <= maxLength;
}