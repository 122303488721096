import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './abort-confirmation-modal.styles.css';
import {CprButton, CprButtonIcon} from 'canopy-styleguide!sofe';
import {a} from 'kremling';
import useFocusTrap from 'src/hooks/use-focus-trap.hook';

AbortConfirmationModal.propTypes = {
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

export default function AbortConfirmationModal(props) {
  const modalRef = useRef(null);

  useFocusTrap(modalRef)

  return (
    <div 
      ref={modalRef}
      className="cps-modal"
    >
      <div className="cps-modal__screen" />
      <div className="cps-modal__dialog cps-card__height-3">
        <div className={a('cps-card__header cps-subheader-sm', styles.header)}>
          <span>Are you sure you want to exit?</span>
          <CprButtonIcon
            icon="close-large"
            ariaText="Cancel exit"
            onClick={props.cancel}
          />
        </div>
        <div className="cps-card__body">
          <p>
            You haven't completed your import yet. If you leave now, your progress will be lost and you'll have to start over.
          </p>
        </div>
        <div className="cps-modal__dialog__actions">
          <CprButton
            actionType="primary"
            onClick={props.confirm}
          >
            Abandon and exit
          </CprButton>
          <CprButton
            actionType="flat"
            onClick={props.cancel}
          >
            Cancel
          </CprButton>
        </div>
      </div>
    </div>
  )
}