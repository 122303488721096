import React, {useContext, useState, useEffect, useRef} from "react"
import PropTypes from 'prop-types';
import {ImporterDispatch} from 'src/importer.context.js'
import styles from './attributes-page.styles.css';
import {a} from 'kremling';
import {CprButton, CprIcon} from 'canopy-styleguide!sofe';
import {doNotImportOption} from 'src/import-contacts-modal.helper';
import AttributesSearch from './attributes-search/attributes-search.component';
import AttributesItem from './attributes-item/attributes-item.component';
import AddCustomFieldItem from './add-custom-field-item/add-custom-field-item.component';


AttributesPage.propTypes = {
  canopyFields: PropTypes.array.isRequired,
  customFields: PropTypes.array.isRequired,
  column: PropTypes.object.isRequired,
  setDropdownIsOpen: PropTypes.func.isRequired,
  setCreateEditNewCustomFieldProps: PropTypes.func.isRequired,
}

const optionsBeforeFieldsCount = 1;

export default function AttributesPage(props) {
  const dispatch = useContext(ImporterDispatch)
  const scrollContainerRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [filteredCanopyFields, setFilteredCanopyFields] = useState([]);
  const [filteredCustomFields, setFilteredCustomFields] = useState([]);
  const [optionFocusIndex, setOptionFocusIndex] = useState(-1);

  useSearchValueFilter();
  useOptionFocus();

  return (
    <div
      onKeyDown={handleAttributesPageKeyDown}
      onMouseLeave={handleAttributesPageMouseLeave}
    >
      <AttributesSearch
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        optionFocusIndex={optionFocusIndex}
        setOptionFocusIndex={setOptionFocusIndex}
      />
      <ul
        ref={scrollContainerRef}
        className={styles.scrollContainer}
        onKeyDown={handleScrollContainerKeyDown}
        role="listbox"
        tabIndex="0"
        aria-activedescendant={optionFocusIndex >= 0 ? `import-contacts_attributes-item_${optionFocusIndex}` : null} 
      >
        {!(filteredCanopyFields.length + filteredCustomFields.length) && (
          <div className={styles.noFieldsFoundContainer}>
            <div className={styles.noFieldsFoundTitle}>
              No attributes found
            </div>
            <div className={styles.noFieldsFoundSubtitle}>
              Please refine your search criteria or select one of the below options.
            </div>
          </div>
        )}
        <AttributesItem
          column={props.column}
          field={doNotImportOption}
          listIndex={0}
          optionFocusIndex={optionFocusIndex}
          setOptionFocusIndex={setOptionFocusIndex}
          selectField={selectField}
          customClassName={styles.doNotImport}
        />
        {filteredCanopyFields.map((field, index) => (
          <AttributesItem
            key={`${field.name}_${index}`}
            column={props.column}
            field={field}
            listIndex={index + optionsBeforeFieldsCount}
            optionFocusIndex={optionFocusIndex}
            setOptionFocusIndex={setOptionFocusIndex}
            selectField={selectField}
            searchValue={searchValue}
          />
        ))}
        {!!filteredCustomFields.length && (
          <>
            <div className={styles.customFieldsHeader}>
              <CprIcon name="misc-text-input" />
              <span className={styles.customFieldsHeaderText}>Custom fields</span>
              <hr />
            </div>
            {filteredCustomFields.map((field, index) => (
              <AttributesItem
                key={`${field.name}_${index}`}
                column={props.column}
                field={field}
                listIndex={index + filteredCanopyFields.length + optionsBeforeFieldsCount}
                optionFocusIndex={optionFocusIndex}
                setOptionFocusIndex={setOptionFocusIndex}
                selectField={selectField}
                searchValue={searchValue}
                setCreateEditNewCustomFieldProps={props.setCreateEditNewCustomFieldProps}
              />
            ))}
          </>
        )}
        <AddCustomFieldItem
          listIndex={filteredCanopyFields.length + filteredCustomFields.length + optionsBeforeFieldsCount}
          optionFocusIndex={optionFocusIndex}
          setOptionFocusIndex={setOptionFocusIndex}
          handleStartAddCustomField={handleStartAddCustomField}
          setDropdownIsOpen={props.setDropdownIsOpen}
          handleBlur={handleAddCustomFieldItemBlur}
        />
      </ul>
    </div>
  )

  function useSearchValueFilter() {
    useEffect(() => {
      const modifiedSearchValue = searchValue.trim().toLowerCase();
      if (modifiedSearchValue) {
        setFilteredCanopyFields(props.canopyFields.filter(field => field.displayLabel.toLowerCase().includes(modifiedSearchValue)));
        setFilteredCustomFields(props.customFields.filter(field => field.displayLabel.toLowerCase().includes(modifiedSearchValue)));
      } else {
        setFilteredCanopyFields(props.canopyFields.filter(field => !field.defaultHidden));
        setFilteredCustomFields(props.customFields.filter(field => !field.defaultHidden));
      }
    }, [searchValue, props.canopyFields, props.customFields])
  }

  function useOptionFocus() {
    useEffect(() => {
      if (scrollContainerRef.current) {
        if (optionFocusIndex < 0) {
          setOptionFocusIndex(-1);
          return;
        }
        
        const listElements = scrollContainerRef.current.querySelectorAll(`li`);
        if (optionFocusIndex >= listElements.length) {
          setOptionFocusIndex(listElements.length - 1);
          return;
        }

        const element = listElements[optionFocusIndex];

        const scrollViewTop = scrollContainerRef.current.scrollTop;
        const scrollViewBottom = scrollContainerRef.current.scrollTop + scrollContainerRef.current.clientHeight;
        const elementTop =  element.offsetTop;
        const elementBottom = element.offsetTop + element.offsetHeight;

        if (elementBottom > scrollViewBottom) {
          element.scrollIntoView(false);
        }

        if (elementTop < scrollViewTop) {
          element.scrollIntoView();
        }
      }
    }, [optionFocusIndex])
  }

  function handleAttributesPageKeyDown(e) {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        scrollContainerRef.current.focus();
        setOptionFocusIndex(previousOptionFocusIndex => ++previousOptionFocusIndex);
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (optionFocusIndex > 0) {
          scrollContainerRef.current.focus();
        }
        setOptionFocusIndex(previousOptionFocusIndex => --previousOptionFocusIndex);
        break;
    }
  }

  function handleAttributesPageMouseLeave(e) {
    setOptionFocusIndex(-1);
  }

  function handleScrollContainerKeyDown(e) {
    switch (e.key) {
      case 'Enter':
        let focusedField;

        if (optionFocusIndex === 0) {
          focusedField = doNotImportOption;
        } else if (optionFocusIndex > 0 && optionFocusIndex <= filteredCanopyFields.length) {
          focusedField = filteredCanopyFields[optionFocusIndex - 1];
        } else if (optionFocusIndex > filteredCanopyFields.length && optionFocusIndex <= (filteredCanopyFields.length + filteredCustomFields.length)) {
          focusedField = filteredCustomFields[optionFocusIndex - filteredCanopyFields.length - 1];
        } else if (optionFocusIndex === (filteredCanopyFields.length + filteredCustomFields.length) + 1) {
          handleStartAddCustomField();
        }

        if (focusedField) {
          selectField(focusedField);
        }
        break;
    }
  }

  function handleStartAddCustomField(clickEvent) {
    if (clickEvent) {
      clickEvent.nativeEvent.keepDropdownOpen = true;
    }
    props.setCreateEditNewCustomFieldProps({});
  }

  function selectField(field) {
    if (field.usedCount && !field.allowMultiple) {
      return;
    }

    dispatch({
      type: 'set_data_column',
      payload: {
        ...props.column,
        field,
      }
    });
    props.setDropdownIsOpen(false);
  }

  function handleAddCustomFieldItemBlur() {
    if (optionFocusIndex > -1) {
      // if optionFocusIndex isn't the search bar, focus the scroll container.
      if (scrollContainerRef && scrollContainerRef.current) {
        scrollContainerRef.current.focus();
      }
    }
  }
}