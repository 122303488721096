import React, {useContext, useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {deleteMappingTemplate} from '../../mapping-resource.js';
import styles from './template-mapping-modal.styles.css';
import {a} from 'kremling';
import TemplateMappingOption from './template-mapping-option/template-mapping-option.component';
import {CprButton} from 'canopy-styleguide!sofe';
import {pages} from '../../import-contacts-modal.helper';
import {ImporterDispatch} from 'src/importer.context';
import {catchAsyncStacktrace} from 'auto-trace';
import {finalize} from 'rxjs/operators';
import {noop} from 'lodash';
import useFocusTrap from 'src/hooks/use-focus-trap.hook';

TemplateMappingModal.propTypes = {
  templates: PropTypes.array.isRequired,
  setSelectedTemplate: PropTypes.func.isRequired,
}

const manualMappingTemplate = {
  id: '__MANUAL_MAPPING_TEMPLATE__',
  editable: false,
  alwaysVisible: true,
  name: 'Map manually',
}

export default function TemplateMappingModal(props) {
  const dispatch = useContext(ImporterDispatch);
  const modalRef = useRef(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [deleteTemplate, setDeleteTemplate] = useState(null);

  useFocusTrap(modalRef);
  useDeleteTemplate();

  return (
    <div
      ref={modalRef}
      className="cps-modal"
    >
      <div className="cps-modal__screen" />
      <div className="cps-modal__dialog cps-card__height-3">
        <div className="cps-card__header cps-subheader-sm">
          Map attributes to your CSV
        </div>
        <div className={a('cps-card__body', styles.body)}>
          <div>
            <p className='cps-wt-bold'>
              To save you time, we've matched your CSV headers with Canopy attributes.
            </p>
            <p>
              You can choose to select a template below that has mapped a percentage of your headers for you or select 'Map manually' to map all headers individually.
            </p>
          </div>
          <div className={`${styles.templatesContainer}`}>
            {props.templates.map((template, index) => (
              <TemplateMappingOption
                key={`${template.id}_${index}`}
                template={template}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
              />
            ))}
          </div>
          <hr className={styles.hr}/>
          <TemplateMappingOption
            template={manualMappingTemplate}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        </div>
        <div className={a('cps-modal__dialog__actions', styles.footer)}>
          <div>
            <CprButton
              actionType="secondary"
              onClick={() => dispatch({type: 'set_page', payload: pages.FILE_UPLOAD})}
            >
              Go back
            </CprButton>
          </div>
          <div>
            <CprButton
              actionType="secondary"
              disabled={!selectedTemplate || !selectedTemplate.editable}
              onClick={() => removeMappingTemplate(selectedTemplate)}
            >
              Delete template
            </CprButton>
            <CprButton
              actionType="primary"
              disabled={!selectedTemplate}
              onClick={() => setMapping()}
            >
              Map
            </CprButton>
          </div>
        </div>
      </div>
    </div>
  );

  function useDeleteTemplate() {
    useEffect(()=> {
      if (deleteTemplate) {
        const subscription = deleteMappingTemplate(deleteTemplate.id).pipe(
          finalize(() => {
            setDeleteTemplate(null);
          })
        )
        .subscribe(
          noop,
          catchAsyncStacktrace()
        )

        return () => subscription.unsubscribe();
      }
    }, [deleteTemplate])
  }

  function removeMappingTemplate(template) {
    setDeleteTemplate(template);

    dispatch({type: 'delete_mapping_template', payload: template});
    setSelectedTemplate(null);
  }

  function setMapping() {
    props.setSelectedTemplate(selectedTemplate);
  }
}